<template>
	<div>
		<vs-prompt
      v-show="isModalBroadcast"
      class="broadcast"
      :title="$t('Broadcast Message')"
      :disabled-outside="true"
      :buttons-hidden="true"
      @close="closeBroadcast()"
      :active.sync="isModalActiveLocal">
      <!-- reply section -->
      <div class="settings-section relative pl-6 pr-3">
        <!-- template -->
        <!-- Type -->
        <vs-tabs>
        <!-- tab 1 -->
          <vs-tab label="Message Template">
            <div class="con-tab-ejemplo">
              <div>
                <!-- broadcast type -->
                <vs-select class="w-full mt-4" v-model="dataType" :placeholder="$t('Select Broadcast template')" @change="onChangeTemplate(dataType)">
                  <vs-select-item :value="email.type" :text="getTypeName(email.type)" v-for="email in emails" :key="email.value"/>
                </vs-select>
              </div>

              <!-- title broadcast -->
              <vs-input v-filter-input
                class="w-full mt-4"
                name="item-title"
                :placeholder="$t('Title')"
                v-model="dataTitle"
              />
              <!-- Content -->
              <vs-textarea  v-filter-input
                class="w-full mt-4 input-message"
                name="item-content"
                rows="10"
                :placeholder="$t('Message')"
                v-model="dataContent"
              />

              <!-- informarion -->
              <div class="mb-4" v-if="dataType">
              {{ $t('The following fixed tags can be included') }}:
                <span class="text-primary text-sm">
                  <span class="mr-1 pointer" v-clipboard:copy="'[ACCESS_CODE]'" v-clipboard:success="onCopy" v-clipboard:error="onError">[ACCESS_CODE]</span>
                  <span class="mr-1 pointer" v-clipboard:copy="'[LOGIN_URL]'" v-clipboard:success="onCopy" v-clipboard:error="onError">[LOGIN_URL]</span>
                  <span class="mr-1 pointer" v-clipboard:copy="'[ICS_URL]'" v-clipboard:success="onCopy" v-clipboard:error="onError">[ICS_URL]</span>
                  <span class="mr-1 pointer" v-clipboard:copy="'[EVENT_NAME]'" v-clipboard:success="onCopy" v-clipboard:error="onError">[EVENT_NAME]</span>
                  <span class="mr-1 pointer" v-clipboard:copy="'[EVENT_OWNER]'" v-clipboard:success="onCopy" v-clipboard:error="onError">[EVENT_OWNER]</span>
                  <span class="mr-1 pointer" v-clipboard:copy="'[EVENT_URL]'" v-clipboard:success="onCopy" v-clipboard:error="onError">[EVENT_URL]</span>
                  <span class="mr-1 pointer" v-clipboard:copy="'[QRCODE]'" v-clipboard:success="onCopy" v-clipboard:error="onError">[QRCODE]</span>
                </span>
              </div>

              <div class="flex justify-between mb-4"  v-if="dataType">
                <div class="w-1/2">
                  <!-- Image upload Buttons -->
                  <div class="modify-img flex items-center">
                    <div>
                      <div v-if="dataFile" class="img-container">
                        <div class="truncate text-blue" style="width: 300px;">{{ dataFileName }}</div>
                      </div>
                      <input
                        type="file"
                        class="hidden"
                        ref="updateAttachment"
                        @change="updateCurrFile"
                        accept="all/*"
                      />
                      <div class="flex">
                        <vs-button color="secondary" type="border" class="px-4 py-1"
                          @click="$refs.updateAttachment.click()">
                          <div class="flex items-center pt-1">
                            <svg class="w-6 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>attachment</title><path d="M7.5,18A5.5,5.5 0 0,1 2,12.5A5.5,5.5 0 0,1 7.5,7H18A4,4 0 0,1 22,11A4,4 0 0,1 18,15H9.5A2.5,2.5 0 0,1 7,12.5A2.5,2.5 0 0,1 9.5,10H17V11.5H9.5A1,1 0 0,0 8.5,12.5A1,1 0 0,0 9.5,13.5H18A2.5,2.5 0 0,0 20.5,11A2.5,2.5 0 0,0 18,8.5H7.5A4,4 0 0,0 3.5,12.5A4,4 0 0,0 7.5,16.5H17V18H7.5Z" /></svg>
                            {{$t('Attachment')}}
                          </div>
                        </vs-button>
                        <vs-button v-if="dataFile" class="ml-2 btn-icon text-black" color="pr" icon="delete" @click="removeDataFile()"></vs-button>
                        <vs-button
                          v-if="dataContent && dataType !== 'message'"
                          class="px-4 ml-4"
                          color="#f3f3f3"
                          text-color="black"
                          @click="embedQR()">
                          <div class="flex items-center">
                            {{ $t('Embed QR') }}
                          </div>
                        </vs-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-danger pt-2 w-1/2 text-right">
                  *{{ $t('Do not edit the text in bracket [ ]') }}
                </div>
              </div>
            </div>
            <div class="items-center flex justify-end"  v-if="dataType">
              <vs-button
                class="py-2 px-6"
                :class="{ 'is-loading': isSending }"
                :disabled="isSending">
                <div class="flex items-center" @click="saveChanges()">
                  <div>{{ $t('Save Changes') }}</div>
                </div>
              </vs-button>
            </div>
          </vs-tab>
          <!-- tab 2 -->
          <vs-tab label="SMTP">
            <div class="con-tab-ejemplo">
              Coming Soon
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </vs-prompt>
    </div>
</template>

<script>
import fileApi from '@/api/file';
import emailApi from '@/api/email';
import {
  getAxiosErrorMessage, duplicateVar,
} from '@/lib/helper';
import { BROADCAST_TEMPLATES } from '@/lib/statics';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export default {
  components: {
  },
  props: {
    isModalBroadcast: {
      type: Boolean,
      default: () => false,
    },
    eventData: {
      type: Object,
      default: () => {},
    },
    idsBroadcast: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    isModalBroadcast() {
      if (this.isModalBroadcast) {
        this.isModalActiveLocal = true;
        this.fetchEmails();
      } else {
        this.isModalActiveLocal = false;
      }
    },
    isModalActiveLocal() {
      if (!this.isModalActiveLocal) this.$emit('closeBroadcast');
    },
  },
  data() {
    return {
      isModalActiveLocal: false,
      isSending: false,
      previousBroadcasts: [],
      dataType: '',
      dataTitle: '',
      dataContent: '',
      dataFile: null,
      dataFileName: '',
      emails: '',
      targets: [
        { title: 'All', value: 'all' },
        { title: 'Selected', value: 'selected' },
        { title: 'Status', value: 'status' },
      ],
      types: [
        { title: 'User Event Registration', value: 'register' },
        { title: 'Invitation', value: 'invitation' },
        { title: 'Reminder', value: 'reminder' },
      ],
      previousTypes: [],
      filterStatuses: [
        { title: 'Invitation Sent', value: 'invitation_sent' },
        { title: 'Invitation broadcast failed', value: 'failed_sent_broadcast' },
        { title: 'Invitation sent failed', value: 'failed_sent_invitation' },
        { title: 'Logged In', value: 'logged_in' },
        { title: 'All', value: 'all' },
      ],
      broadcastTemplates: duplicateVar(BROADCAST_TEMPLATES),
    };
  },
  computed: {
    isFormValid() {
      return this.dataAnnouncement !== '';
    },
    slug() {
      return this.$route.params.eventSlug;
    },
  },
  methods: {
    getTypeName(value) {
      const type = this.types.find(t => t.value === value);
      return type ? type.title : 'Unknown';
    },
    embedQR() {
      if (!this.dataContent.includes('[QRCODE]')) {
        this.dataContent = `${this.dataContent}

[QRCODE]`;
      }
    },
    onChangeTemplate(type) {
      const template = this.emails.find(t => t.type === type);
      if (template) {
        this.dataTitle = template.title;
        this.dataContent = template.content;
        this.dataType = template.type;
        this.dataFile = template.file;
        this.dataFileName = template.file;
      }
    },

    closeBroadcast() {
      this.reset();
      this.isModalActiveLocal = false;
    },

    updateCurrFile(input) {
      if (input.target.files && input.target.files[0]) {
        this.isUploading = true;
        const file = input.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.dataFile = e.target.result;
        };
        reader.readAsDataURL(file);

        // Upload Image
        const formData = new FormData();
        formData.append('file', file);
        const callback = (response) => {
          const url = response.data;
          this.dataFile = url;
          this.dataFileName = file.name;
          this.isUploading = false;
        };
        const errorCallback = (e) => {
          const message = getAxiosErrorMessage(e);
          this.$vs.notify({
            title: this.$t('Upload Image'),
            text: message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          });
          this.isUploading = false;
        };

        fileApi.upload(formData, callback, errorCallback);
      }
    },
    removeDataFile() {
      this.dataFileName = '';
      this.dataFile = null;
      this.$refs.updateAttachment.value = '';
    },
    reset() {
      this.dataType = '';
      this.dataTitle = '';
      this.dataContent = '';
      this.dataFile = null;
      this.dataFileName = '';
    },
    onCopy() {
      this.$vs.notify({
        text: 'Copied to clipboard',
        color: 'success',
        iconPack: 'feather',
        position: 'bottom-right',
        icon: 'icon-check-circle',
      });
    },
    onError() {
      this.$vs.notify({
        title: 'Failed',
        text: 'Error in copying',
        color: 'danger',
        iconPack: 'feather',
        position: 'bottom-right',
        icon: 'icon-alert-circle',
      });
    },

    fetchEmails() {
      this.$vs.loading();
      this.emails = [];
      const params = {
        page: 1,
        limit: 9999,
        event_id: this.eventData && this.eventData.id ? this.eventData.id : null,
      };
      const callback = (response) => {
        const emails = response.data;
        this.emails = emails;
        this.isFetching = false;
        this.$vs.loading.close();
      };
      const errorCallback = () => {
        this.isFetching = false;
        this.$vs.loading.close();
      };
      emailApi.list(params, callback, errorCallback);
    },

    saveChanges() {
      this.isSaving = true;
      const notifTitle = this.$t('Email template');
      const callback = () => {
        // const message = response.message;
        const message = this.$t('Your changes have been saved.');
        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
        this.isSaving = false;
        this.closeBroadcast();
      };

      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isSaving = false;
        this.closeBroadcast();
      };
      const params = {
        title: this.dataTitle,
        content: this.dataContent,
        type: this.dataType,
        file: this.dataFile,
        event_id: this.eventData && this.eventData.id ? this.eventData.id : null,
      };
      emailApi.create(params, callback, errorCallback);
    },
  },
  mounted() {
  },
  created() {
  },
  destroyed() {
  },
};
</script>
<style lang="scss">
  .settings-section {
      margin-top: 0px;
      width: 98%;
      min-height: 210px;
  }

  .approve.con-vs-dialog .vs-dialog .vs-dialog-text {
      padding: 0!important;
  }

  @media screen and (min-width: 600px) {
    .broadcast.con-vs-dialog .vs-dialog {
      min-width: 600px!important;
    }
  }
  .vs-select--options .previous-select span {
    color: #Cccccc; /* Change to your desired color */
  }
</style>
