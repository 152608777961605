import { render, staticRenderFns } from "./ModalBroadcastSettings.vue?vue&type=template&id=623e2e63"
import script from "./ModalBroadcastSettings.vue?vue&type=script&lang=js"
export * from "./ModalBroadcastSettings.vue?vue&type=script&lang=js"
import style0 from "./ModalBroadcastSettings.vue?vue&type=style&index=0&id=623e2e63&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports